import React from "react"
import Layout from "../layout";


// markup
const IndexPage = () => {
  return (<Layout>
    {"Home"}
  </Layout>);
}

export default IndexPage
